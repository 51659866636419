import data from '../data/data.json';

function getTechPerc(name) {
    let nb_project = 0;
    data.projects.forEach(project => {
        project.technologies.forEach(tech => {
            if (tech === name) {
                nb_project++;
            }
        });
    });
    return (nb_project/data.projects.length)*100;
}

function getTechRow(name, color, index) {
    let tech_perc = getTechPerc(name); 
    return (
        <div key={index}>
        <progress className={"progress w-56 "+color} value={tech_perc} max="100"></progress><br/>{tech_perc+ '% '+ name}
        </div>
    );
}

export default function Skills() {
    let lang_rows = [];
    let fram_rows = [];
    let lib_rows = [];
    let tools_rows = [];
    data.technologies.languages.forEach((lang, index) => {
        lang_rows.push(getTechRow(lang, "progress-primary", index))
    });
    data.technologies.frameworks.forEach((fram,index) => {
        fram_rows.push(getTechRow(fram,"progress-secondary", index))
    });
    data.technologies.libraries.forEach((lib,index) => {
        lib_rows.push(getTechRow(lib,"progress-accent", index))
    });
    data.technologies.tools.forEach((tool,index) => {
        tools_rows.push(<div key={"p-"+index}><div key={index} className="badge badge-primary">{tool.name}</div>{' : '+tool.description}</div>)
    })
    return (
        <div className="text-center bg-base-200">
            <h1 className="text-3xl font-bold">Skills</h1>
            <br/>
            <p>Instead of giving a percentage of what technology I think I know the best, I decided to give a percentage of use of a technology among all my projects</p><br/>
            
            <div className="flex justify-center">
                <div className="flex flex-col lg:flex-row ">
                    <div className="card text-justify">
                        <h4 className="text-xl font-bold">Languages</h4>
                        {lang_rows}
                    </div>
                    <div className="divider lg:divider-horizontal"></div> 
                    <div className="card text-justify">
                        <h4 className="text-xl font-bold">Frameworks</h4>
                        {fram_rows}
                    </div>
                    <div className="divider lg:divider-horizontal"></div>
                    <div className="card text-justify">
                        <h4 className="text-xl font-bold">Libraries</h4>
                        {lib_rows}
                    </div>
                    <div className="divider lg:divider-horizontal"></div>
                    <div className="card text-justify">
                        <h4 className="text-xl font-bold">Tools</h4>
                        {tools_rows}
                    </div>
                </div>
            </div>
        </div>
    );
}