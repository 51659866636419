import ProjectCard from "./project_card";
import data from '../data/data.json';

export default function Projects() {
    let projectsList = [];
    let side = true;
    data.projects.forEach((project) => {
        projectsList.push(
            <ProjectCard
                key={project.id}
                year={project.date} 
                title={project.name}
                type={project.type}
                school={project.school}
                description={project.description}
                side={side}
                link={project.link}
                linkName={project.linkName}
                technologies={project.technologies}
            />
        )
        side = !side;
   });

    return (
        <div id="projects">
            <div className="flex justify-center items-center">
                <h1 className="text-3xl font-bold">Projects</h1>
            </div>
            <br/>
            <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
                {projectsList}
            </ul>
        </div>
    );
}