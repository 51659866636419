import Skills from "./skills";
import Education from "./education";
import Experience from "./experience";

export default function About() {
    return (
      
      <div className="hero bg-base-200" id="about">
        <div className="hero-content text-center">
          <div className=" max-w-5xl">
          
            <h1 className="text-5xl font-bold">About</h1>
            
            <p className="py-6">Welcome to my digital hub! I am a dedicated IT enthusiast, 
            spending my free time crafting practical solutions to everyday hurdles. 
            From self-hosting servers to optimizing workflows with Docker and VMs, 
            I leverage my hands-on experience to drive innovation. 
            Lets collaborate and shape the future of tech together!</p>
            <Education/>
            <br/>
            <Skills/>
            <br/>
            <Experience/>
          </div>
        </div>
        <br/>
      </div>
    );
}