export default function Experience() {
    return (
        <div className="overflow-x-auto">
            <h1 className="text-3xl font-bold">Experience</h1>
            <br/>
            <table className="table table-sm">
                {/* head */}
                <thead>
                <tr>
                    <th>Company</th>
                    <th>Job</th>
                    <th>Description</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                {/* row 1 */}
                <tr >
                    <td><a href="https://www.advanced-schema.com/" target="_blank" rel="noopener noreferrer">Advanced Schema</a></td>
                    <td>Full Stack Developer Intern</td>
                    <td>Design and development of data manipulation applications using SCRUM methodology</td>
                    <td>April - July 2024</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}