import data from '../data/data.json';
import { v4 as uuid } from 'uuid';

export default function ProjectCard(props) {
    const { year, title, type, school, description, side, link, linkName, technologies } = props;
    return (
        <li key={uuid()}>
            <hr />
            <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
            </div>
            <div className={side ? "timeline-start mb-10" : "timeline-end mb-10"}>
                <time className="font-mono italic">{year}</time>
                <div className="text-lg font-black">{title}</div>
                <div className="font-mono italic">Type: {type}</div>
                { school? <div className="font-mono italic">School: {school}</div> : null }
                {linkName ? 
                <div className="font-mono ">
                    Link: 
                    <a href={link} target="_blank" rel="noopener noreferrer" className="text-orange-400">{linkName}</a>
                </div> : null 
                }
                <button className="btn" onClick={()=>{
                    let modal = document.getElementById('dialog-'+title);
                    if (modal) {
                        modal.showModal();
                    }
                }}>See more</button>

                <ProjectDialog year={year} title={title} type={type} school={school} description={description} link={link} linkName={linkName} technologies={technologies}/>
                
            </div>
            <hr />
        </li>
    );
}

function ProjectDialog(props) {
    const { year, title, type, school, description, link, linkName, technologies } = props;
    return(
        <dialog id={'dialog-'+title} className="modal modal-bottom sm:modal-middle">
            <div className="modal-box">
                <h3 className="font-bold text-lg">{title}</h3>
                <p className="py-4">{description}</p>
                <div className="py-4">{getTechBadges(technologies)}</div>
                <div className="font-mono italic">Date: {year}</div>
                <div className="font-mono italic">Type: {type}</div>
                { school? <div className="font-mono italic">School: {school}</div> : null }
                {linkName ? 
                <div className="font-mono ">
                    Link: 
                    <a href={link} target="_blank" rel="noopener noreferrer" className="text-orange-400">{linkName}</a>
                </div> : null 
                }
                <div className="py-4">{getTechBadgesLegend()}</div>
                <div className="modal-action"> 
                <form method="dialog">
                    <button className="btn">Close</button>
                </form>
                </div>
            </div>
        </dialog>
    );
}

function getTechBadges(technologies) {
    let techList = [];
    technologies.forEach(tech => {
        let color = "badge-primary";
        if (data.technologies.languages.includes(tech)) {
            color = "badge-primary";
        }
        if (data.technologies.frameworks.includes(tech)) {
            color = "badge-secondary";
        }
        if (data.technologies.libraries.includes(tech)) {
            color = "badge-accent";
        }
        techList.push(<><div className={"badge "+color}>{tech}</div> </>)
    });
    return techList
}

function getTechBadgesLegend() {
    let legend = [];
    legend.push(<><div className="badge badge-primary badge-outline">Language</div> </>);
    legend.push(<><div className="badge badge-secondary badge-outline">Framework</div> </>);
    legend.push(<><div className="badge badge-accent badge-outline">Library</div><br/></>);
    return legend;
}