import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Projects from "./projects/page";
import About from "./about/page";
import Stats from "./about/stats";

function ClovisAge() {
  const currentDate = new Date();
  return (currentDate.getFullYear() - 2002);
}

export default function App() {
  return (
    <div>
      <Navbar/>
      <div className="hero min-h-screen bg-base-200">
        <div className="hero-content flex-col lg:flex-row">
          <img className="w-full h-auto rounded-lg shadow-2xl lg:max-w-sm" src="/moi.jpg" alt="Clovis Krzyzanowski"/>
          <div>
            <h1 className="text-5xl font-bold">Clovis Krzyzanowski</h1>
            <p className="py-6">{ ClovisAge() } year old CS student currently studying at UTC (Université Technologique de Compiègne).
            </p>
            <div>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#ComputerScience</span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Paris</span>
            </div>
            <a href="/Clovis_Krzyzanowski_CV.pdf"><button className="btn btn-primary">Download CV</button></a>
          </div>
        </div>
      </div>
      <Stats/>
      <About/>
      <Projects/>
      <Footer/>
    </div>
  );
};
