import data from '../data/data.json'

function getTechnologiesNB() {
    return (data.technologies.frameworks.length + 
    data.technologies.languages.length + data.technologies.libraries.length);
}


export default function Stats() {
    
    return(
        <div className="bg-base-200 text-center ">
        <div className="stats stats-vertical lg:stats-horizontal shadow">
  
            <div className="stat">
                <div className="stat-figure text-primary">
                    <div className="w-16 rounded-full">
                        <svg fill="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>language_xml</title><rect width="24" height="24" fill="none"/><path d="M12.89,3l2,.4L11.11,21l-2-.4L12.89,3m6.7,9L16,8.41V5.58L22.42,12,16,18.41V15.58L19.59,12m-18,0L8,5.58V8.41L4.41,12,8,15.58v2.83Z"/></svg>
                    </div>
                </div>
                <div className="stat-title">Programming Languages</div>
                <div className="stat-value ">{data.technologies.languages.length}</div>
                <div className="stat-desc">for the last {new Date().getFullYear() - 2020} years</div>
            </div>
            
            <div className="stat">
                <div className="stat-figure text-secondary">
                    <div className="w-16 rounded-full">
                        <svg fill="#61dafb" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>react</title><rect width="24" height="24" fill="none"/><path d="M12,10.11A1.87,1.87,0,1,1,10.13,12,1.88,1.88,0,0,1,12,10.11M7.37,20c.63.38,2-.2,3.6-1.7a24.22,24.22,0,0,1-1.51-1.9A22.7,22.7,0,0,1,7.06,16c-.51,2.14-.32,3.61.31,4m.71-5.74-.29-.51a7.91,7.91,0,0,0-.29.86c.27.06.57.11.88.16l-.3-.51m6.54-.76.81-1.5-.81-1.5c-.3-.53-.62-1-.91-1.47C13.17,9,12.6,9,12,9s-1.17,0-1.71,0c-.29.47-.61.94-.91,1.47L8.57,12l.81,1.5c.3.53.62,1,.91,1.47.54,0,1.11,0,1.71,0s1.17,0,1.71,0c.29-.47.61-.94.91-1.47M12,6.78c-.19.22-.39.45-.59.72h1.18c-.2-.27-.4-.5-.59-.72m0,10.44c.19-.22.39-.45.59-.72H11.41c.2.27.4.5.59.72M16.62,4c-.62-.38-2,.2-3.59,1.7a24.22,24.22,0,0,1,1.51,1.9,22.7,22.7,0,0,1,2.4.36c.51-2.14.32-3.61-.32-4m-.7,5.74.29.51a7.91,7.91,0,0,0,.29-.86c-.27-.06-.57-.11-.88-.16l.3.51m1.45-7c1.47.84,1.63,3.05,1,5.63,2.54.75,4.37,2,4.37,3.68s-1.83,2.93-4.37,3.68c.62,2.58.46,4.79-1,5.63s-3.45-.12-5.37-1.95c-1.92,1.83-3.91,2.79-5.38,1.95s-1.62-3-1-5.63c-2.54-.75-4.37-2-4.37-3.68S3.08,9.07,5.62,8.32c-.62-2.58-.46-4.79,1-5.63s3.46.12,5.38,1.95c1.92-1.83,3.91-2.79,5.37-1.95M17.08,12A22.51,22.51,0,0,1,18,14.26c2.1-.63,3.28-1.53,3.28-2.26S20.07,10.37,18,9.74A22.51,22.51,0,0,1,17.08,12M6.92,12A22.51,22.51,0,0,1,6,9.74c-2.1.63-3.28,1.53-3.28,2.26S3.93,13.63,6,14.26A22.51,22.51,0,0,1,6.92,12m9,2.26-.3.51c.31,0,.61-.1.88-.16a7.91,7.91,0,0,0-.29-.86l-.29.51M13,18.3c1.59,1.5,3,2.08,3.59,1.7s.83-1.82.32-4a22.7,22.7,0,0,1-2.4.36A24.22,24.22,0,0,1,13,18.3M8.08,9.74l.3-.51c-.31,0-.61.1-.88.16a7.91,7.91,0,0,0,.29.86l.29-.51M11,5.7C9.38,4.2,8,3.62,7.37,4s-.82,1.82-.31,4a22.7,22.7,0,0,1,2.4-.36A24.22,24.22,0,0,1,11,5.7Z"/></svg>
                    </div>
                </div>
                <div className="stat-title">Used Frameworks</div>
                <div className="stat-value ">{data.technologies.frameworks.length}</div>
                <div className="stat-desc">with {data.technologies.libraries.length} external libraries</div>
            </div>
            
            <div className="stat">
                <div className="stat-figure text-secondary">
                
                    <div className="w-16 rounded-full">
                    
                    
                    <svg viewBox="0 0 32 32" enableBackground="new 0 0 32 32" version="1.1"  xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Energy20"></g> <g id="Energy19"></g> <g id="Energy18"></g> <g id="Energy17"></g> <g id="Energy16"></g> <g id="Energy15"></g> <g id="Energy14"></g> <g id="Energy13"></g> <g id="Energy12"></g> <g id="Energy11"></g> <g id="Energy10"> <g> <path d="M26,11c0,2.53-0.95,4.94-2.67,6.8C22.47,18.72,22,19.86,22,21c0,0.55-0.45,1-1,1H11c-0.55,0-1-0.45-1-1 c0-1.14-0.47-2.28-1.33-3.2C6.93,15.93,5.98,13.48,6,10.93C6.04,5.6,10.4,1.15,15.72,1H16C21.51,1,26,5.49,26,11z" fill="#FFB841"></path> </g> <g> <path d="M20,27c0,0.35-0.04,0.68-0.13,1c-0.44,1.73-2.01,3-3.87,3s-3.43-1.27-3.87-3C12.04,27.68,12,27.35,12,27 c0-0.55,0.45-1,1-1h6C19.55,26,20,26.45,20,27z" fill="#624F81"></path> </g> <g> <path d="M22,21v5c0,1.1-0.9,2-2,2h-8c-1.1,0-2-0.9-2-2v-5c0-0.55,0.45-1,1-1h10C21.55,20,22,20.45,22,21z" fill="#BF4D48"></path> </g> <g> <rect fill="#DB5E5B" height="2" width="12" x="10" y="23"></rect> </g> </g> <g id="Energy09"></g> <g id="Energy08"></g> <g id="Energy07"></g> <g id="Energy06"></g> <g id="Energy05"></g> <g id="Energy04"></g> <g id="Energy03"></g> <g id="Energy02"></g> <g id="Energy01"></g> </g></svg>
                    </div>
                
                </div>
                <div className="stat-value">{data.projects.length}</div>
                <div className="stat-title">Projects done</div>
                <div className="stat-desc text-secondary">in {getTechnologiesNB()} distinct technologies</div>
            </div>
            
            </div>
            </div>
    )
}